<template>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center" :class="`icon-${variant}`">
              <i :class="`${icon} text-${variant}`"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">{{ category }}</p>
              <p class="card-title">{{ number }}</p>

              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <hr />
        <router-link v-if="linkTo" :to="linkTo">{{ linkText }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
      default: "nc-icon nc-briefcase-24"
    },
    category: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    variant: {
      type: String,
      required: false,
      default: "info"
    },
    linkTo: {
      type: [String, Object, Boolean],
      required: false,
      default: false
    },
    linkText: {
      type: String,
      required: false,
      default: "View all"
    }
  }
};
</script>
<style scoped>
.card-footer {
  min-height: 62px;
}
</style>
