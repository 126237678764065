<template>
  <div>
    <div class="row">
      <StatsCard
        :number="offerCount"
        :category="$gettext('Offers')"
        icon="jbsmd-job"
        variant="warning"
        :link-to="offerLinkTo"
        :link-text="
          offerCount ? $gettext('View all offers') : $gettext('Create an offer')
        "
      />

      <StatsCard
        :number="candidateCount"
        :category="$gettext('Candidates')"
        icon="jbsmd-candidate"
        variant="info"
        :link-to="candidateLinkTo"
        :link-text="
          candidateCount
            ? $gettext('View all candidates')
            : $gettext('Create a candidate')
        "
      />

      <StatsCard
        :number="companyCount"
        :category="$gettext('Companies')"
        icon="jbsmd-building"
        variant="danger"
        :link-to="companyLinkTo"
        :link-text="
          companyCount
            ? $gettext('View all companies')
            : $gettext('Create a company')
        "
      />

      <StatsCard
        :number="userCount"
        :category="$gettext('Users')"
        icon="jbsmd-member"
        variant="success"
        :link-to="userLinkTo"
        :link-text="$gettext('View all users')"
      />
    </div>
    <div class="row">
      <div class="col-md-7">
        <BarChart
          id="applications-by-status"
          :title="$gettext('Applications by status')"
          group-by="status"
          :metrics="[{ id: 'application_count', label: '# Applications' }]"
          :fetch-data="barData"
          :legend="false"
          :begin-at-zero="true"
          @element-clicked="handleApplicationByStatusElementClicked"
        />
      </div>
      <div class="col-md-5">
        <DoughnutChart
          id="top-publishers"
          :title="$gettext('Applications by source')"
          group-by="label"
          :metrics="[{ id: 'application_count', label: '# Applications' }]"
          :fetch-data="doughnutData"
          @element-clicked="handleApplicationBySourceElementClicked"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import StatsCard from "@/views/Dashboard/StatsCard";
import BarChart from "@/components/Cards/BarChart";
import DoughnutChart from "@/components/Cards/DoughnutChart";
import { createFilter, Operator } from "@/utils/filters";

export default {
  components: { StatsCard, BarChart, DoughnutChart },
  data() {
    return {
      chartDataIsLoaded: null
    };
  },
  created() {
    this.chartDataIsLoaded = new Promise(resolve => {
      this.fetch().finally(() => {
        this.stopFetching();
        resolve();
      });
    });
  },
  computed: {
    ...mapGetters("dashboard", [
      "offerCount",
      "applicationCount",
      "companyCount",
      "candidateCount",
      "userCount",
      "applications",
      "publishers"
    ]),
    ...mapGetters("me", [
      "canManageOffers",
      "canManageCandidates",
      "canManageCompanies",
      "canManageUsers"
    ]),
    ...mapGetters("applications", {
      applicationGetSchemaColumn: "getSchemaColumn"
    }),
    offerLinkTo() {
      return !this.canManageOffers
        ? false
        : this.offerCount
        ? "/offers"
        : "/create-offer";
    },
    candidateLinkTo() {
      return !this.canManageCandidates
        ? false
        : this.candidateCount
        ? "/candidates"
        : "/create-candidate";
    },
    companyLinkTo() {
      return !this.canManageCompanies
        ? false
        : this.companyCount
        ? "/companies"
        : "/create-company";
    },
    userLinkTo() {
      return this.canManageUsers ? "/users" : false;
    }
  },
  methods: {
    ...mapActions("dashboard", ["fetch"]),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("applications", { fetchApplicationSchema: "fetchSchema" }),
    ...mapMutations("applications", {
      addApplicationFilter: "addFilter",
      clearApplicationFilters: "clearFilters"
    }),
    barData() {
      return new Promise(resolve => {
        this.chartDataIsLoaded.then(() => {
          const maximum =
            Math.max(...this.applications.map(a => a.application_count)) + 1;
          resolve({
            data: this.applications,
            maximum
          });
        });
      });
    },
    doughnutData() {
      return new Promise(resolve => {
        this.chartDataIsLoaded.then(() => {
          resolve({
            data: this.publishers
          });
        });
      });
    },
    async handleApplicationByStatusElementClicked({ chartElements, response }) {
      const index = chartElements?.[0]?._index;
      const status = response?.[index]?.status;
      if (status) {
        this.clearApplicationFilters();
        await this.fetchApplicationSchema();
        const filter = createFilter();
        const column = this.applicationGetSchemaColumn("status");
        filter.setColumn(column);
        filter.setOperator(Operator.Equal);
        filter.value = column.options.values.filter(
          option => option.option_id === status
        );
        this.addApplicationFilter(filter);
        await this.$router.push({
          name: "applications"
        });
      }
    },
    async handleApplicationBySourceElementClicked({ chartElements, response }) {
      const index = chartElements?.[0]?._index;
      const source = response?.[index]?.source;
      if (source) {
        this.clearApplicationFilters();
        await this.fetchApplicationSchema();
        const filter = createFilter();
        const column = this.applicationGetSchemaColumn("source");
        filter.setColumn(column);
        filter.setOperator(Operator.Equal);
        filter.value = column.options.values.filter(
          option => option.option_id === source
        );
        this.addApplicationFilter(filter);
        await this.$router.push({
          name: "applications"
        });
      }
    }
  }
};
</script>
