<template>
  <div class="card card-chart">
    <div class="card-header">
      <h5 class="card-title">{{ title }}</h5>
    </div>
    <div class="card-body">
      <div class="text-center">
        <BSpinner class="text-center" v-if="isLoading" />
      </div>

      <canvas height="400" :id="id"></canvas>
    </div>
    <div class="card-footer"></div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { Paired12 } from "chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      required: true,
      type: String
    },
    groupBy: {
      type: String,
      required: true
    },
    metrics: {
      type: Array,
      required: true
    },
    fetchData: {
      required: true,
      type: Function
    },
    legend: {
      type: Boolean,
      default: true
    },
    beginAtZero: {
      type: Boolean,
      default: true
    },
    stepSize: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isLoading: true
    };
  },
  mounted() {
    this.fetchData()
      .then(this.buildOptions)
      .then(this.render)
      .finally(() => (this.isLoading = false));
  },
  methods: {
    render(options) {
      const ctx = document.getElementById(this.id).getContext("2d");
      new Chart(ctx, options);
    },
    setDataLabels(groupBy) {
      return (labels, row) => {
        labels.push(this.$gettext(row[groupBy]));
        return labels;
      };
    },
    setDatasets(metrics) {
      return (datasets, row) => {
        return metrics.reduce((acc, metric, index) => {
          if (datasets[index] === undefined) {
            datasets[index] = {
              label: metric.label,
              data: [],
              backgroundColor: Paired12
            };
          }
          datasets[index].data.push(row[metric.id]);
          return datasets;
        }, datasets);
      };
    },
    buildOptions({ data: response, maximum }) {
      const options = {
        options: {
          plugins: {
            colorschemes: {
              scheme: Paired12
            }
          },
          legend: {
            display: this.legend
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: this.beginAtZero,
                  max: maximum,
                  stepSize: this.stepSize
                }
              }
            ]
          },
          maintainAspectRatio: false,
          onHover: this.onHoverHandler,
          onClick: this.clickHandler(response)
        },
        type: "bar",
        data: {
          labels: [],
          datasets: []
        }
      };

      options.data.labels = response.reduce(
        this.setDataLabels(this.groupBy),
        []
      );
      options.data.datasets = response.reduce(
        this.setDatasets(this.metrics),
        []
      );
      return options;
    },
    onHoverHandler(event, chartElements) {
      if (chartElements.length) {
        event.target.style.cursor = "pointer";
      } else {
        event.target.style.cursor = "default";
      }
    },
    clickHandler(response) {
      return (event, chartElements) => {
        this.$emit("element-clicked", {
          chartElements,
          response
        });
      };
    }
  }
};
</script>
